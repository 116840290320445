@import "../../../variables.scss";

.skill {
  b {
    font-weight: 700;
    color: $white;
  }
}

@keyframes pulse {
  0%,
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }
}

.pulse {
  animation: pulse infinite 1s;
  -webkite-animation: pulse infinite 1s;
}
