@import "../../../variables.scss";

#header {
  // background-color: transparent;
  background-color: rgba($bg-dark-hover, 0.95);

  &.bg-dark {
    background-color: rgba($bg-dark-hover, 0.95);
  }

  .navbar-nav {
    .nav-link {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.1em;
      color: $white;
      text-align: center;
      margin: auto 4px;

      &:hover,
      &.active {
        font-weight: 500;
        background-color: $primary-hover;
        color: $bg-dark;
        border-radius: 5px;
      }
    }
  }
}
