@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import "~normalize.css/normalize.css";
@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";

$theme-colors: (
  "primary": $primary,
);

*:active,
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-dark-hover;
  color: rgba($white, 0.75);
}

.bg-dark-gradient {
  background-image: linear-gradient(
    to right bottom,
    $bg-dark 0,
    $bg-dark-hover
  );
}

.text-primary {
  color: $primary !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.waves {
  position: relative;

  &.padding {
    padding-bottom: 300px !important;
  }

  &::after {
    content: " ";
    background-image: url("./assets/bg-waves.svg");
    background-attachment: scroll;
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    right: 0;
    bottom: -100px;
    left: 0;
    opacity: 0.15;
    z-index: -1;
    // -webkit-animation: move 3s infinite ease-in-out;
    // animation: move 3s infinite ease-in-out;
  }
}

@keyframes move {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(100vw, 0, 0); /* The image width */
  }
}

@media screen and (max-width: 767px) {
  .waves::after {
    background-size: 200% auto;
  }
}
