@import "./variables.scss";
:root {
  --rainbow-gradient: linear-gradient(-90deg,#adfbda 0,#35c3ff 30%,#fda399 50%,#76d880 70%,#ebf38b 90%,#adfbda 100%);
}

.tagline {
  font-size: 50px;
  font-weight: 300;
  color: $white;
  margin: 30px 0;
}

.text-gradient {
  background: var(--rainbow-gradient);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  filter: drop-shadow(0 0 2rem #000);
  text-shadow: none!important;
}

.gradient-bg {
  border-radius: 5px;
  background: linear-gradient(120deg, #6a11cb 0%, #6a11cb 40%, #2575fc 100%);
  transition: all 1s ease;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.5);
}

.main-view {
  min-height: calc(100vh - 65px);
  padding-top: 65px;
}

.hero-header {
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  color: $white;
  padding: 0 15px;

  .content {
    padding: 30px 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .col-12 {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 24px;
    color: $primary;
  }

  .highlight {
    font-size: 75px;
    font-weight: 900;
    margin: 0;
  }

  .desc {
    font-size: 50px;
    font-weight: 300;
    color: $white;
    margin: 30px 0;
  }
}

.business-card {
  border-radius: 5px;
  padding: 2px;
  background: linear-gradient(to right bottom, $primary-hover, $secondary);

  pre {
    border-radius: 5px;
    background-color: rgba($bg-dark-alternate-hover, 1);
    white-space: break-spaces;
    color: $white;
    font-weight: 700;
    padding: 20px;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .intro-text {
    text-align: center;
  }

  .tagline {
    font-size: 45px;
  }
}
